exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-angielskie-js": () => import("./../../../src/pages/oferta/angielskie.js" /* webpackChunkName: "component---src-pages-oferta-angielskie-js" */),
  "component---src-pages-oferta-do-akryli-js": () => import("./../../../src/pages/oferta/do-akryli.js" /* webpackChunkName: "component---src-pages-oferta-do-akryli-js" */),
  "component---src-pages-oferta-inne-js": () => import("./../../../src/pages/oferta/inne.js" /* webpackChunkName: "component---src-pages-oferta-inne-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-oferta-kaloryferowe-js": () => import("./../../../src/pages/oferta/kaloryferowe.js" /* webpackChunkName: "component---src-pages-oferta-kaloryferowe-js" */),
  "component---src-pages-oferta-lawkowce-js": () => import("./../../../src/pages/oferta/lawkowce.js" /* webpackChunkName: "component---src-pages-oferta-lawkowce-js" */),
  "component---src-pages-oferta-lawkowczyki-js": () => import("./../../../src/pages/oferta/lawkowczyki.js" /* webpackChunkName: "component---src-pages-oferta-lawkowczyki-js" */),
  "component---src-pages-oferta-okragle-js": () => import("./../../../src/pages/oferta/okragle.js" /* webpackChunkName: "component---src-pages-oferta-okragle-js" */),
  "component---src-pages-oferta-paskowe-js": () => import("./../../../src/pages/oferta/paskowe.js" /* webpackChunkName: "component---src-pages-oferta-paskowe-js" */),
  "component---src-pages-oferta-polangielskie-js": () => import("./../../../src/pages/oferta/polangielskie.js" /* webpackChunkName: "component---src-pages-oferta-polangielskie-js" */),
  "component---src-pages-oferta-profesjonalne-js": () => import("./../../../src/pages/oferta/profesjonalne.js" /* webpackChunkName: "component---src-pages-oferta-profesjonalne-js" */),
  "component---src-pages-oferta-szczotki-js": () => import("./../../../src/pages/oferta/szczotki.js" /* webpackChunkName: "component---src-pages-oferta-szczotki-js" */),
  "component---src-pages-oferta-tapetowe-js": () => import("./../../../src/pages/oferta/tapetowe.js" /* webpackChunkName: "component---src-pages-oferta-tapetowe-js" */),
  "component---src-pages-oferta-zamiatacze-js": () => import("./../../../src/pages/oferta/zamiatacze.js" /* webpackChunkName: "component---src-pages-oferta-zamiatacze-js" */),
  "component---src-pages-oferta-zmiotki-js": () => import("./../../../src/pages/oferta/zmiotki.js" /* webpackChunkName: "component---src-pages-oferta-zmiotki-js" */)
}

